import React from "react";

import styles from "./Archive.module.scss";
import WorkPreview from "../../layout/WorkPreview/WorkPreview";
import { Title } from "../../common/Title/Title";

const Archive = ({ works }) => {
  return (
    <section className={styles.page}>
      {/* <Title>Projects</Title> */}

      <div className={styles.pool}>
        {works.map(work => (
          <WorkPreview key={work.slug.current} {...work} />
        ))}
      </div>
    </section>
  );
};

export { Archive };
