import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/content/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers";
import { Archive } from "../components/pages/Archive/Archive";

export const query = graphql`
  query WorksPageQuery {
    works: allSanityWork(
      limit: 12
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
              fluid(maxWidth: 1920) {
                ...GatsbySanityImageFluid
              }
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const WorksPage = ({ data, errors, location }) => {
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  const workNodes =
    data && data.works && mapEdgesToNodes(data.works).filter(filterOutDocsWithoutSlugs);

  return (
    <Layout location={location}>
      <SEO title="Works" />
      <Archive works={workNodes} />
    </Layout>
  );
};

export default WorksPage;
