import { Link } from "gatsby";
import React, { useMemo } from "react";
import Img from "gatsby-image";
import classNames from "classnames";
import { buildImageObj } from "../../../lib/helpers";
import { imageUrlFor } from "../../../lib/image-url";

import styles from "./WorkPreview.module.scss";
import { Typography } from "../../common/Typography/Typography";
import BlockContent from "../../content/block-content";
import BlockText from "../../content/block-text";

function WorkPreview({ mainImage, slug, title, _rawExcerpt }) {
  return (
    <Link className={classNames(styles.root, styles.fullWidth)} to={`/work/${slug.current}`}>
      <div className={styles.image}>
        {mainImage && mainImage.asset && (
          // <img
          //   src={imageUrlFor(buildImageObj(mainImage))
          //     .width(1600)
          //     .height(Math.floor((9 / 16) * 1600))
          //     .fit("crop")
          //     .url()}
          //   alt={mainImage.alt}
          // />
          <Img fluid={mainImage.asset.fluid} alt={mainImage.alt} />
        )}
      </div>
      <div className={styles.textContainer}>
        <Typography type="heading" tagName="h2" className={styles.text}>
          {title}
        </Typography>
        <Typography type="paragraph" tagName="span" className={styles.text}>
          <BlockText blocks={_rawExcerpt || []} />
        </Typography>
      </div>
    </Link>
  );
}

export default WorkPreview;
